@import './App.css';
@import './responsive.css';
@import './tailwind.css';
@import './fonts.css';
@import './Addifier.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glass_bg {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.infinite-scroll-component {
  overflow: unset !important;
}

.apexcharts-toolbar {
  margin-top: -35px;
}

.payment-setup-button {
  width: 6rem;
  border-width: 0px;
}
.roboto-font {
  font-family: 'Roboto';
}
.montserrat-font {
  font-family: 'Montserrat';
}
