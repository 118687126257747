.ant-select-selector {
  height: 40px !important;
}

.ant-picker {
  height: 40px !important;
  border-radius: 6px !important;
}

.ant-input-affix-wrapper {
  height: 40px !important;
  border-radius: 6px !important;
}

.ant-input-affix-wrapper .ant-input {
  height: unset !important;
}

.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.mobile-toggler {
  display: none !important;
}

.desktop-toggler {
  display: block !important;
}

@media only screen and (max-width: 992px) {
  .mobile-toggler {
    display: block !important;
  }
  .desktop-toggler {
    display: none !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ant-tabs-tab {
  color: #363636 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Pagination Color Management */
.ant-pagination-item-active {
  background: #14b1e0;
}
.ant-pagination-item-active a {
  color: white;
}
.ant-pagination-item {
  border: 2px solid #14b1e0;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #14b1e0;
}

.modal_height_mobile {
  height: calc(100vh - 200px);
}

.modal_height_desktop {
  height: 600px;
}

.ant-tabs-tab-active {
  background-color: #14b1e0;
  border-radius: 12px;
  color: white !important;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-weight: 600;
}

/* .ant-tabs-nav-wrap {
  width: 100% !important;
  overflow-x: auto !important;
} */

@media screen and (max-width: 500px) {
  /* .ant-tabs-nav-list {
    flex-wrap: wrap;
    justify-content: flex-start;
  } */
  .ant-tabs-tab {
    width: unset !important;
    padding: 1rem 1rem;
    margin-right: 1rem !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0 !important;
  }

  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}
.ant-switch {
  background-color: gray !important;
}

.ant-switch-checked {
  background-color: #14b1e0 !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.ant-tabs-nav-operations-hidden {
  display: none !important;
}

.ant-pagination-item-ellipsis {
  display: flex !important;
}

/* To hide arrow of tooltip */
/* .hide-arrow > .ant-tooltip-arrow {
  display: none !important;
} */

.ant-carousel .slick-dots li.slick-active button {
  background: #14b1e0 !important;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1000px;
  border: 1px solid #14b1e0;
}

.ant-carousel .slick-dots li {
  width: unset !important;
  margin-right: 0.5rem;
}

.ant-carousel .slick-dots li button {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1000px;
  border: 1px solid #14b1e0;
}

.ant-carousel .slick-dots-bottom {
  bottom: 35px !important;
}

@media screen and (max-width: 500px) {
  .ant-carousel .slick-dots-bottom {
    bottom: 8px !important;
  }
}

img.ant-image-preview-img {
  display: inline-block !important;
}

/* This is temporary to handle antd upload throwing error */
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #14b1e0 !important;
}

@media screen and (max-width: 500px) {
  .mobile-zero-padding .ant-table-tbody > tr > td,
  .mobile-zero-padding .ant-table-thead > tr > th {
    padding: 8px 1px !important;
  }
}

.space-between-radio {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.horizontal-override > .ant-form-item > {
  flex-direction: inherit;
}

.horizontal-override > .ant-form-item > .ant-form-item-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.horizontal-override,
.ant-form-item-label {
  margin-top: 0.2rem;
}
.uploader-class,
.ant-upload {
  width: 100%;
}

.td-right tbody tr .align-right {
  text-align: right !important;
}
.td-right tbody tr .align-center {
  text-align: center !important;
  padding-left: 0;
}

.ant-roboto-font .ant-form-item-label {
  font-family: 'Roboto';
}
.monserrat-font .ant-form-item-label {
  font-family: 'Montserrat';
}

.donor-detai-moda .ant-modal-content,
.donor-detai-moda .ant-modal-header {
  background-color: #f7f8f9;
  box-shadow: none;
  /* border-radius: 10%; */
}

.select-border-red
  > .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #ff0000 !important;
}
