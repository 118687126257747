@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .modalHeight {
    height: calc(100vh - 15rem) !important;
  }
}
.ant-btn-primary {
  background-color: #14b1e0;
}
