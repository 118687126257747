@font-face {
  font-family: 'PalatinoFont';
  src: url('assets/fonts/Palatino\ Font.woff2') format('woff2'),
    url('assets/fonts/Palatino\ Font.woff') format('woff'),
    url('assets/fonts/Palatino\ Font.ttf') format('truetype');
}

.font-palatino {
  font-family: 'PalatinoFont';
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/Roboto/Roboto-Regular.ttf') format('truetype');
}

.font-roboto {
  font-family: 'Roboto';
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

.font-Montserrat {
  font-family: 'Montserrat';
}
